import DateTimeComp from './components/DateTimeComp'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to orbitalforrest.de</h1>
        <p>
          This page is currently  under maintenance.
        </p>
      <div><DateTimeComp></DateTimeComp></div>
      </header>
    </div>
  );
}

export default App;
